import React, {useEffect, useState} from "react";
import User from "../../../entities/user";
import {closingCfpsEndpoint, handleResponse} from "../../../utils/api";
import {Box, Button, ContentLayout, Spinner, Tabs} from "@cloudscape-design/components";
import {ErrorAlert} from "../../custom_components/alert";
import Header from "@cloudscape-design/components/header";
import {COLUMN_DEFINITIONS, FILTERING_PROPERTIES} from "./config";
import {FilteredTable} from "../../custom_components/filtered_table/table";
import {useNavigate} from "react-router-dom";

export const Content = () => {
    const [conferences, setConferences] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(null)

    const navigate = useNavigate()

    useEffect(() => {
        retrieveConferences()
    }, []);

    // # ---------------------- HELPERS --------------------- # //
    function retrieveConferences() {
        setIsLoading(true)
        setError(null)

        const options = {headers: {Authorization: User.shared().token}}

        fetch(closingCfpsEndpoint(), options)
            .then(handleResponse)
            .then(json => setConferences(json.item))
            .catch(error => setError({title: 'The conferences could not be retrieved', message: error.message}))
            .finally(() => setIsLoading(false))
    }

    // # -------------------- COMPONENTS -------------------- # //
    const LoadingContent = () => (
        <Box textAlign={"center"} padding={{vertical: 'l'}} color='text-status-inactive'>
            <Spinner size="normal" /> Retrieving conferences...
        </Box>
    )

    const EmptyContent = () => (
        <Box padding={{vertical: 'l'}} textAlign={'center'} color={'text-status-inactive'}>
            <b>No call for papers closing soon</b>

            <Box
                padding={{bottom: "s"}}
                variant="p"
                color="inherit"
            >
                The CFPs of upcoming conferences will remain open for a couple of days. You still have time to work on your submissions 😁
            </Box>

            <Button onClick={() => navigate('/conferences')}>Explore upcoming conferences</Button>
        </Box>
    )

    const ErrorContent = () => (
        <Box padding={{vertical: 'l'}}>
            <ErrorAlert {...error}></ErrorAlert>
        </Box>
    )

    const ConferencesTable = ({items}) => (
        <FilteredTable
            texts={{}}
            data={items}
            loading={false}
            columnDefinitions={COLUMN_DEFINITIONS}
            filteringProperties={FILTERING_PROPERTIES}
            sortingColumn={COLUMN_DEFINITIONS[3]}
            stickyHeader={true}
            stripedRows={true}
            header={
                <Header
                    variant="h2"
                    counter={'(' + items.length + ')'}
                >
                    Conferences
                </Header>
            }
        />
    )

    function ConferencesContent() {
        let tabs = []

        Object.keys(conferences).forEach(function (key, index) {
            tabs.push({
                label: key,
                id: index,
                content: <ConferencesTable items={conferences[key]}></ConferencesTable>
            })
        })

        return (
            isLoading ? (
                <LoadingContent/>
            ) : (
                tabs.length === 0
                ? <EmptyContent/>
                : <Tabs tabs={tabs}/>
            )
        )
    }

    return (
        <ContentLayout
            disableOverlap={true}
            header={
                <Header
                    variant={'h1'}
                    description={'In this page you can view the conferences whose CFP deadlines are closing soon. To view the details of a conference, click on its name.'}
                >
                    CFPs closing soon
                </Header>
            }
        >
            {error === null
                ? <ConferencesContent />
                : <ErrorContent {...error} />
            }
        </ContentLayout>
    )
}