import {Content} from "./content"
import {BaseAppLayout} from "../../../app/baseAppLayout";
import {NotificationCenter} from "../../custom_components/flashbar";
import useNotificationCenter from "../../../utils/notificationCenter";

export const ManageConference = () => {
    const notificationCenter = useNotificationCenter()

    return (
        <BaseAppLayout
            notifications={<NotificationCenter notifications={notificationCenter.notifications}/>}
            content={<Content notificationCenter={notificationCenter}/>}
            toolsHide={true}
            disableContentPaddings={false}
        />
    )
}