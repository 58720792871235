import * as React from "react";
import HelpPanel from "@cloudscape-design/components/help-panel";
import {BaseLink} from "../components/custom_components/link";

export const BaseHelpPanel = (props) => {
    return (
        <HelpPanel
            header={<h2>About the project</h2>}
            footer={
                <div>
                    <ul>
                        <li>
                            <BaseLink external href="https://gitlab.aws.dev/bpguasch/aws-event-finder-tracker/-/issues">Submit a bug or feature request</BaseLink>
                        </li>
                        <li>
                            <BaseLink external href="https://amzn-aws.slack.com/archives/C06BPTC3T19">Join the conversation on Slack!</BaseLink>
                        </li>
                        <li>
                            <BaseLink external href="https://t.corp.amazon.com/create/options?category=AWS%20WWSO&type=Applications&item=Event%20Finder&group=event-finder-admins">Having issues? Open a ticket</BaseLink>
                        </li>
                    </ul>
                </div>
            }
        >
            <div>
                <p>
                    This tool was born as the internship project of Elena Madrazo (<BaseLink external href="https://phonetool.amazon.com/users/emadrazo">@emadrazo</BaseLink>) in the <BaseLink external href="https://w.amazon.com/bin/view/Specialist_Acceleration/">Specialist Acceleration (SAx)</BaseLink> team. Elena was mentored by Borja Pérez (<BaseLink external href="https://phonetool.amazon.com/users/bpguasch">@bpguasch</BaseLink>) and had stakeholders from the WWSO Compute team. <br></br><br></br> The idea of creating a tool like this arose from the need to have a centralized place to find public speaking opportunities and to scale the knowledge of the talks given by other Amazonians. When Elena's internship ended and the usefulness of AWS Event Finder was proved by means of Elena's PoC, Borja took up the project and turned it into an internal tool with the support of SAx member Piotrek Witkowski (<BaseLink external href="https://phonetool.amazon.com/users/piwit">@piwit</BaseLink>) and SAx EMEA manager Luis Orus (<BaseLink external href="https://phonetool.amazon.com/users/luisgrac">@luisgrac</BaseLink>).
                </p>
            </div>
        </HelpPanel>
    );
};