import React, {useEffect, useState} from "react";
import {
    Alert,
    Box, Button, Form, Input, Modal, Select, SpaceBetween
} from "@cloudscape-design/components";
import Header from "@cloudscape-design/components/header";
import {COLUMN_DEFINITIONS, FILTERING_PROPERTIES} from "./config";
import {
    getSubscriptionEndpoint,
    handleResponse, subscriptionsEndpoint,
    userSubscriptionsEndpoint
} from "../../utils/api";
import {FilteredTable} from "../custom_components/filtered_table/table";
import User from "../../entities/user";
import {ErrorAlert} from "../custom_components/alert";
import {useParams} from "react-router-dom";
import FormField from "@cloudscape-design/components/form-field";

const TEXTS = {loadingText: 'Retrieving subscriptions...', emptyTitle: 'No subscriptions', emptyMessage: 'To create a subscription, click on the "Create a subscription" button.'}


export const Content = ({notificationCenter}) => {
    const username = useParams()['username']

    const [subscriptions, setSubscriptions] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)

    const [selectedItems, setSelectedItems] = useState([])

    const [isInDeletionMode, setIsInDeletionMode] = useState(false);
    const [isConfirmingDeletion, setIsConfirmingDeletion] = useState(false);

    const [isInCreationMode, setIsInCreationMode] = useState(false);
    const [isConfirmingCreation, setIsConfirmingCreation] = useState(false);

    useEffect(() => {
        getSubscriptions(username)
    }, [username]);

    // # ---------------------- HELPERS --------------------- # //
    function getSubscriptions(username) {
        setIsLoading(true)
        setError(null)
        setIsInDeletionMode(false)
        setIsConfirmingDeletion(false)
        setIsInCreationMode(false)
        setIsConfirmingCreation(false)

        const options = {headers: {Authorization: User.shared().token}}

        fetch(userSubscriptionsEndpoint(username), options)
            .then(handleResponse)
            .then(json => setSubscriptions(json.items))
            .catch(error => setError({title: 'The subscriptions could not be retrieved', message: error.message}))
            .finally(() => setIsLoading(false))
    }

    function toggleCreationMode() {
        setIsConfirmingCreation(false)
        setIsInCreationMode(!isInCreationMode)
    }

    function confirmCreation(type, topic) {
        const options = {
            method: 'POST',
            headers: {Authorization: User.shared().token},
            body: JSON.stringify({'type': type, 'topic': topic})
        }

        // Dismiss the modal
        setIsInCreationMode(false)
        setIsConfirmingCreation(true)

        const createId = notificationCenter.addInfoNotification('Creating subscription "' + topic + '"...',
            'Please, wait while the subscription is being created.')

        fetch(subscriptionsEndpoint(), options)
            .then(handleResponse)
            .then(json => {
                notificationCenter.addSuccessfulNotification('Subscription "' + topic + '" successfully created!')
                setSubscriptions(subscriptions => [json, ...subscriptions])
            })
            .catch(error => notificationCenter.addErrorNotification('The subscription "' + topic + '" could not be created', error.message))
            .finally(() => {
                setIsConfirmingCreation(false)
                notificationCenter.dismissNotification(createId)
            })
    }

    function toggleDeletionMode() {
        setIsConfirmingDeletion(false)
        setIsInDeletionMode(!isInDeletionMode)
    }

    function confirmDeletion() {
        const subscription = selectedItems[0]

        const options = {
            method: 'DELETE',
            headers: {Authorization: User.shared().token}
        }

        // Dismiss the modal
        setIsInDeletionMode(false)
        setIsConfirmingDeletion(true)

        const deleteId = notificationCenter.addInfoNotification('Deleting subscription "' + subscription.topic + '"...',
            'Please, wait while the subscription is being deleted.')

        fetch(getSubscriptionEndpoint(subscription.id), options)
            .then(handleResponse)
            .then(() => {
                notificationCenter.addSuccessfulNotification('Subscription "' + subscription.topic + '" successfully deleted!')
                setSelectedItems([])
                setSubscriptions(subscriptions => subscriptions.filter(s => s.id !== subscription.id));
            })
            .catch(error => notificationCenter.addErrorNotification('The subscription "' + subscription.topic + '" could not be deleted', error.message))
            .finally(() => {
                setIsConfirmingDeletion(false)
                notificationCenter.dismissNotification(deleteId)
            })
    }

    function isPerformingUpdates() {
        return isConfirmingCreation || isConfirmingDeletion
    }

    // # -------------------- COMPONENTS -------------------- # //
    const ErrorContent = () => (
        <Box padding={{vertical: 'l'}}>
            <ErrorAlert {...error}></ErrorAlert>
        </Box>
    )

    const ActionItems = () => (
        <SpaceBetween size={'m'} direction={'horizontal'}>
            <Button disabled={isPerformingUpdates()} onClick={toggleCreationMode}>Create a subscription</Button>
            <Button variant={'primary'} disabled={selectedItems.length === 0 || isPerformingUpdates()} onClick={toggleDeletionMode}>Delete</Button>
        </SpaceBetween>
    )

    function DeleteModal() {
        return (
            <Modal visible={isInDeletionMode}
                   onDismiss={toggleDeletionMode}
                   header={'Delete subscription'}
                   footer={
                       <Box float="right">
                           <SpaceBetween direction="horizontal" size="xs">
                               <Button variant="link" onClick={toggleDeletionMode}>
                                   Cancel
                               </Button>
                               <Button variant="primary" onClick={confirmDeletion}>
                                   Delete
                               </Button>
                           </SpaceBetween>
                       </Box>
                   }
            >
                <SpaceBetween size="m">
                    <Box variant="span">
                        Permanently delete subscription? You can’t undo this action.
                    </Box>

                    <Alert type="info">
                        Proceeding with this action will delete the subscription, and you will stop receiving automated emails that fall under its scope.
                    </Alert>
                </SpaceBetween>
            </Modal>
        )
    }

    function CreateModal() {
        const TYPE_MAPPING = {'city': 'City', 'country': 'Country', 'continent': 'Continent', 'format': 'Event format'}
        const FORMAT_MAPPING = {'IN_PERSON': 'In person', 'VIRTUAL': 'Virtual', 'HYBRID': 'Hybrid'}

        const [type, setType] = useState('')
        const [topic, setTopic] = useState('')
        const [format, setFormat] = useState('')

        useEffect(() => {
            setType('city')
            setTopic('')
            setFormat('IN_PERSON')
        }, []);

        function getTopicValue() {
            return type === 'format' ? FORMAT_MAPPING[format] : topic
        }

        return (
            <Modal visible={isInCreationMode}
                   onDismiss={toggleCreationMode}
                   header={
                        <Header description={''}>
                            Create a subscription
                        </Header>
                   }
                   footer={
                       <Box float="right">
                           <SpaceBetween direction="horizontal" size="xs">
                               <Button variant="link" onClick={toggleCreationMode}>
                                   Cancel
                               </Button>
                               <Button variant="primary" disabled={!topic && type !== 'format'} onClick={() => confirmCreation(type, getTopicValue())}>
                                   Create
                               </Button>
                           </SpaceBetween>
                       </Box>
                   }
            >
                <form onSubmit={e => e.preventDefault()}>
                    <Form>
                        <SpaceBetween size="l" direction={'vertical'}>
                            <FormField label='Type' description={'Represents a conference attribute.'}>
                                <Select
                                    selectedOption={{label: TYPE_MAPPING[type], value: type}}
                                    onChange={event => setType(event.detail.selectedOption.value)}
                                    options={[
                                        {label: "City", value: "city"},
                                        {label: "Country", value: "country"},
                                        {label: "Continent", value: "continent"},
                                        {label: "Event format", value: "format"}
                                    ]}
                                />
                            </FormField>

                            <FormField label='Topic' description={'Attribute value on the basis of which you want to receive alerts.'}>
                                {type === 'format'
                                    ? <Select
                                        selectedOption={{label: FORMAT_MAPPING[format], value: format}}
                                        onChange={event => setFormat(event.detail.selectedOption.value)}
                                        options={[
                                            {label: "Virtual", value: "VIRTUAL"},
                                            {label: "In person", value: "IN_PERSON"},
                                            {label: "Hybrid", value: "HYBRID"}
                                        ]}
                                    />
                                    : <Input
                                        placeholder={'The name of a city, country or continent'}
                                        value={topic}
                                        onChange={event => setTopic(event.detail.value)}
                                        ariaRequired={true}
                                    />
                                }

                            </FormField>
                        </SpaceBetween>
                    </Form>
                </form>
            </Modal>
        )
    }

    const SubscriptionsContent = () => (
        <FilteredTable
            variant={'full-page'}
            selectionType={'single'}
            selectedItems={selectedItems}
            onSelectionChange={({ detail }) =>
                setSelectedItems(detail.selectedItems)
            }
            data={subscriptions}
            loading={isLoading}
            texts={TEXTS}
            columnDefinitions={COLUMN_DEFINITIONS}
            filteringProperties={FILTERING_PROPERTIES}
            sortingColumn={COLUMN_DEFINITIONS[0]}
            stickyHeader={true}
            stripedRows={true}
            sortingDescending={false}
            header={
                <Header
                    variant={'h1'}
                    actions={<ActionItems/>}
                    description={'Subscriptions allow you to receive email communications when new conferences are found or changes in existing conferences are detected.'}
                    counter={'(' + subscriptions.length + ')'}
                >
                    My subscriptions
                </Header>
            }
        />
    )

    return error ? (
        <ErrorContent />
    ) : (
        <>
            <SubscriptionsContent></SubscriptionsContent>
            <DeleteModal/>
            <CreateModal/>
        </>
    );
}