import * as React from "react";
import SideNavigation from "@cloudscape-design/components/side-navigation";
import {useNavigate} from "react-router-dom";
import {env} from "../utils/amplify";
import {Badge} from "@cloudscape-design/components";
import User from "../entities/user";

export const BaseSideNavigation = () => {
    const myTopicsHref = '/' + User.shared().getAttributes().username + "/submissions"
    const mySubscriptionsHref = '/' + User.shared().getAttributes().username + "/subscriptions"

    let navigate = useNavigate()
    let items = [
        {type: "link", text: "Upcoming conferences", href: "/conferences"},
        {type: "link", text: "CFPs closing soon", href: "/closing-cfps"},
        {type: "divider" },
        {type: "link", text: "My subscriptions", href: mySubscriptionsHref},
        {type: "link", text: "My proposed topics", href: myTopicsHref},
        {type: "divider"},
        {type: "link", text: "Explore proposed topics", href: "/submissions-discovery"},
    ]

    if (env === 'dev') {
        items.push(
            {type: "divider"},
            {type: "link", text: <Badge color="blue">DEV environment</Badge>}
        )
    }

    function findActiveHref() {
        if (window.location.href.includes(myTopicsHref)) return myTopicsHref
        if (window.location.href.includes(mySubscriptionsHref)) return mySubscriptionsHref

        const hrefs = ['/conferences', '/closing-cfps', '/subscriptions', '/submissions-discovery']

        for (let i = 0; i < hrefs.length; i++) {
            if (window.location.href.includes(hrefs[i])) return hrefs[i]
        }
    }

    return (
        <SideNavigation
            activeHref={findActiveHref()}
            header={{href: "/", text: "AWS Event Finder"}}
            onFollow={event => {
                if (!event.detail.external) {
                    event.preventDefault();
                    navigate(event.detail.href)
                }
            }}
            items={items}
        />
    );
}