import {Badge} from "@cloudscape-design/components";
import {BaseLink} from "../components/custom_components/link";

const REJECTED = 'REJECTED'
const ACCEPTED = 'ACCEPTED'

export const getStatus = (submission) => {
    let color = 'grey'

    if (submission.status === REJECTED) color = 'red'
    else if (submission.status === ACCEPTED) color = 'green'

    return (<Badge color={color}>{submission.status}</Badge>)
}

export const getSubmitter = (submission) => {
    return (
        <div>
            <BaseLink href={'/' + submission.ownerAttributes.username + '/submissions'}>{submission.ownerAttributes.name}</BaseLink>
        </div>
    )
}

export const getConference = (submission) => {
    return (
        <div>
            <BaseLink href={'/conferences/' + submission.conference.id}>{submission.conference.name}</BaseLink>
        </div>
    )
}

export const getVisibility = (submission) => {
    return (<Badge color={submission.isPublic? 'green' : 'red'}>{submission.isPublic? 'Public' : 'Private'}</Badge>)
}

export const getAbstractVisibility = (submission) => {
    return (<Badge color={submission.isAbstractPublic? 'green' : 'red'}>{submission.isAbstractPublic? 'Public' : 'Private'}</Badge>)
}