import React, {useEffect, useState} from "react";
import {
    Box
} from "@cloudscape-design/components";
import Header from "@cloudscape-design/components/header";
import User from "../../../entities/user";
import {handleResponse, submissionsEndpoint} from "../../../utils/api";
import {ErrorAlert} from "../../custom_components/alert";
import {FilteredTable} from "../../custom_components/filtered_table/table";
import {COLUMN_DEFINITIONS, FILTERING_PROPERTIES} from "./config";

const TEXTS = {loadingText: "Retrieving proposed topics...", emptyTitle: 'No proposed topics', emptyMessage: 'To add a topic proposal, navigate to the details page of a conference and click on the "Add topic proposal" button.'}

export const Content = () => {
    const [submissions, setSubmissions] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        getSubmissions()
    }, []);

    // # ---------------------- HELPERS --------------------- # //
    function getSubmissions() {
        setIsLoading(true)
        setError(null)

        const options = {headers: {Authorization: User.shared().token}}

        fetch(submissionsEndpoint(), options)
            .then(handleResponse)
            .then(json => hydrateSubmissions(json.items))
            .catch(error => setError({title: 'The topic proposals could not be retrieved', message: error.message}))
            .finally(() => setIsLoading(false))
    }

    function hydrateSubmissions(submissions) {
        for (let i = 0; i < submissions.length; i++) {
            submissions[i].submitterName = submissions[i].ownerAttributes.name
            submissions[i].submitterUsername = submissions[i].ownerAttributes.username
        }

        setSubmissions(submissions)
    }

    // # -------------------- COMPONENTS -------------------- # //
    const ErrorContent = () => (
        <Box padding={{vertical: 'l'}}>
            <ErrorAlert {...error}></ErrorAlert>
        </Box>
    )

    const SubmissionsContent = () => (
        <FilteredTable
            data={submissions}
            loading={isLoading}
            texts={TEXTS}
            columnDefinitions={COLUMN_DEFINITIONS}
            filteringProperties={FILTERING_PROPERTIES}
            sortingColumn={COLUMN_DEFINITIONS[3]}
            variant={'full-page'}
            stickyHeader={true}
            stripedRows={true}
            sortingDescending={true}
            header={
                <Header
                    variant="h1"
                    counter={'(' + submissions.length + ')'}
                    description={'You can view the details of a topic proposal by clicking on its title, the details of the conference it was submitted for by clicking on its title, and all the proposals of a user by clicking on its name.'}
                >
                    Proposed topics

                </Header>
            }
        />
    )

    return error ? (
        <ErrorContent />
    ) : (
        <SubmissionsContent></SubmissionsContent>
    );
}