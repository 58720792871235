import {Content} from "./content"
import {BaseAppLayout} from "../../app/baseAppLayout";

export const Home = () => {
    return (
        <BaseAppLayout
            content={<Content />}
            disableContentPaddings={false}
        />
    )
}