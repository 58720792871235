import {formatDateTime} from "../../../utils/date";
import {getStatus, getSubmitter} from "../../../utils/submission";
import {BaseLink} from "../../custom_components/link";

const rawColumns = [
    {
        id: 'title',
        sortingField: 'title',
        header: 'Title',
        cell: item =>  (
            <div>
                <BaseLink href={"/conferences/" + item.conf_id + "/" + item.id}>{item.title}</BaseLink>
            </div>
        ),
        size: 'Medium',
    },
    {
        id: 'createdOn',
        sortingField: 'createdOn',
        header: 'Created on',
        cell: item => formatDateTime(item.createdOn),
        size: 'Medium',
    },
    {
        id: 'status',
        sortingField: 'status',
        header: 'Status',
        cell: item => getStatus(item),
        size: 'Small',
    },
    {
        id: 'submitter',
        sortingField: 'submitter',
        header: 'Submitter',
        cell: item => getSubmitter(item),
        size: 'Small',
    }
];

export const FILTERING_PROPERTIES = [
    {
        propertyLabel: 'Title',
        key: 'title',
        groupValuesLabel: 'Titles',
        operators: [':', '!:', '=', '!='],
    },
    {
        propertyLabel: 'Submitter',
        key: 'submitter',
        groupValuesLabel: 'Submitters',
        operators: ['=', '!='],
    }
];

export const COLUMN_DEFINITIONS = rawColumns.map(column => ({...column}));