export const config = {
    'title': 'AWS Event Finder',
    'subtitle': 'Attend, learn, share',
    'description': 'AWS Event Finder is a tool that compiles a list of internal and external events for professionals to review, get notified, and attend for speaking. Join today!',
    'features': [
        {
            name: "Periodic discovery of new conferences",
            description: "Save hours of searching thanks to the automatic discovery of new conferences. The tool retrieves data from different sources so that you don't miss a single conference!",
        },
        {
            name: "Automatic subscription-based notifications",
            description: "Subscribe to different topics, such a country, a city or a continent, and get notified by email when there are new conferences that match your preferences.",
        },
        {
            name: "Detailed conference browsing",
            description: "Browse the list of available conferences and filter them by different parameters, including geographic location and topics. See topics proposed by others that have been shared publicly.",
        },
        {
            name: "Proposal tracking and sharing",
            description: "Keep an archive of your proposed topics and track whether your submissions are accepted. You can choose to make a topic proposal publicly available to scale the knowledge of your team.",
        }
    ],
    "howItWorks": "AWS Event Finder obtains information about events periodically from different data sources. Subsequently, it processes, normalizes and stores them. When new events are found or changes in existing events are detected, the tool notifies by email those users who have subscribed to the topics that identify the events obtained, such as the country, the format of the event or the topics the event is about. Additionally, when it detects that the call for papers (CFP) deadline for an event is about to close soon, AWS Event Finder uses those same subscriptions to notify interested users."
}
