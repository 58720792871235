
let User = (function () {
    class UserClass {
        #cognitoData;

        constructor(cognitoData) {
            this.#cognitoData = cognitoData
            this.token = cognitoData.signInUserSession.idToken.jwtToken
        }

        getAttributes() {
            const payload = this.#cognitoData.signInUserSession.idToken.payload

            return {
                username: payload.nickname,
                name: payload.name,
                email: payload.email
            };
        }

        owns(entity) {
            if (entity === null || !entity.owner) return false

            return entity.owner === this.getAttributes().username
        }

        isTester() {
            return this.#cognitoData.signInUserSession.idToken.payload['cognito:groups'].includes('Testers')
        }
    }

    let instance = null

    return {
        init: function (cognitoData) {
            instance = new UserClass(cognitoData)
            delete instance.constructor
        },

        shared: function () {
            return instance
        }
    };
})();

export default User