import { useCallback, useState } from 'react';
import {v4 as uuid4} from 'uuid';

export default function useNotificationCenter() {
    const [notifications, setNotifications] = useState([]);

    const dismissNotification = useCallback((id) => {
        setNotifications(notifications => notifications.filter(notification => notification.id !== id));
    }, []);

    const addNotification = useCallback((type, loading, title, message, dismissible) => {
        const id = uuid4()

        const notification = {
            'type': type,
            'loading': loading,
            'dismissible': dismissible,
            'id': id,
            'onDismiss': () => dismissNotification(id),
            'header': title,
            'content': message
        }

        setNotifications(notifications => {return [notification, ...notifications]})

        return id
    }, [dismissNotification]);

    const addInfoNotification = (title, message, loading = true, dismissible = false) => {
        return addNotification('info', loading, title, message, dismissible)
    }

    const addErrorNotification = (title, message, dismissible = true) => {
        return addNotification('error', false, title, message, dismissible)
    }

    const addSuccessfulNotification = (title, message = '', dismissible = true) => {
        return addNotification('success', false, title, message, dismissible)
    }

    const clearNotifications = useCallback(() => {
        setNotifications(notifications => {return []});
    }, []);

    return {notifications, addInfoNotification, addErrorNotification, addSuccessfulNotification, dismissNotification, clearNotifications};
}