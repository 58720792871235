import {Box, Button, Pagination, PropertyFilter, SpaceBetween, Table} from "@cloudscape-design/components";
import {useCollection} from "@cloudscape-design/collection-hooks";
import {propertyFilterI18nStrings, getTextFilterCounterText} from "./propertyFilter";

export const TableNoMatchState = props => (
    <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
        <SpaceBetween size="xxs">
            <div>
                <b>No matches</b>
                <Box variant="p" color="inherit">
                    We can't find a match.
                </Box>
            </div>
            <Button onClick={props.onClearFilter}>Clear filter</Button>
        </SpaceBetween>
    </Box>
);

export const TableEmptyState = ({title, subtitle}) => (
    <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
        <SpaceBetween size="xxs">
            <div>
                <b>{title}</b>
                <Box variant="p" color="inherit">
                    {subtitle}
                </Box>
            </div>
        </SpaceBetween>
    </Box>
);

export const FilteredTable = (props) => {
    const filteringProperties = props.filteringProperties
    const {items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps} = useCollection(
        props.data,
        {
            propertyFiltering: {
                filteringProperties,
                empty: <TableEmptyState title={props.texts.emptyTitle} subtitle={props.texts.emptyMessage}/>,
                noMatch: (
                    <TableNoMatchState
                        onClearFilter={() => {
                            actions.setPropertyFiltering({ tokens: [], operation: 'and' });
                        }}
                    />
                ),
            },
            pagination: {pageSize: 30},
            sorting: {defaultState: {sortingColumn: props.sortingColumn, isDescending: props.sortingDescending || false}},
            selection: {},
        }
    );

    return (
        <Table
            {...collectionProps}
            {...props}
            loadingText={props.texts.loadingText}
            items={items}
            resizableColumns={true}
            wrapLines={true}
            pagination={
                <Pagination
                    {...paginationProps}
                />
            }
            filter={
                <PropertyFilter
                    {...props.texts}
                    {...propertyFilterProps}
                    i18nStrings={propertyFilterI18nStrings}
                    countText={getTextFilterCounterText(filteredItemsCount)}
                    expandToViewport={true}
                />
            }
        />
    );
}