import {Alert} from "@cloudscape-design/components";

export const ErrorAlert = (props) => {
    return (
        <Alert
            {...props}
            type={'error'}
            header={props.title}
        >
            {props.message}
        </Alert>
    );
}