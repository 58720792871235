import {formatDateTime} from "../../utils/date";

const rawColumns = [
    {
        id: 'type',
        sortingField: 'type',
        header: 'Type',
        cell: item => item.type,
        size: 'Small',
    },
    {
        id: 'topic',
        sortingField: 'topic',
        header: 'Topic',
        cell: item => item.topic,
        size: 'Small',
    },
    {
        id: 'createdOn',
        sortingField: 'createdOn',
        header: 'Created on',
        cell: item => formatDateTime(item.createdOn),
        size: 'Small',
    },
];

export const FILTERING_PROPERTIES = [
    {
        propertyLabel: 'Topic',
        key: 'topic',
        groupValuesLabel: 'Topics',
        operators: ['=', '!='],
    },
    {
        propertyLabel: 'Type',
        key: 'type',
        groupValuesLabel: 'Types',
        operators: ['=', '!='],
    }
];

export const COLUMN_DEFINITIONS = rawColumns.map(column => ({...column}));