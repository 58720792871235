import {Content} from "./content"
import {BaseAppLayout} from "../../../app/baseAppLayout";

export const RetrieveConferences = (props) => {
    return (
        <BaseAppLayout
            content={<Content />}
            toolsHide={true}
            contentType={'table'}
            disableContentPaddings={false}
        />
    )
}