import {Amplify} from 'aws-amplify';

const PROD_HOSTNAME = 'eventfinder.wwso.aws.dev'

export const ENV_DEV = 'dev'
export const ENV_PROD = 'prod'

export const env = window.location.hostname === PROD_HOSTNAME? ENV_PROD : ENV_DEV

const REGION = 'eu-west-1'
const USER_POOL_ID = 'eu-west-1_jNlaoe3cE'
const DOMAIN = 'bpguasch-event-finder.auth.eu-west-1.amazoncognito.com'
const CLIENT_IDS = {
    'dev': '41dep61cn5rcm2gpq5lo6uts9m',
    'prod': '5kbdokajou8ltsb659hch6gq67'
}

const setupAmplify = () => {
    Amplify.configure({
        Auth: {
            mandatorySignIn: true,
            region: REGION,
            userPoolId: USER_POOL_ID,
            userPoolWebClientId: CLIENT_IDS[env],
            oauth: {
                domain: DOMAIN,
                scope: ["openid"],
                redirectSignIn: window.location.origin,
                responseType: "code",
            }
        }
    });
}

export default setupAmplify;