import React, {useEffect, useState} from "react";
import {HashRouter} from 'react-router-dom';
import {Auth} from "aws-amplify";
import setupAmplify, {env, ENV_DEV} from '../utils/amplify';
import {PageRouter} from "./pageRouter";
import './App.css';
import User from '../entities/user'
import {Box, Modal, Spinner} from "@cloudscape-design/components";
import {ErrorAlert} from "../components/custom_components/alert";

export const App = () => {
  const [isSignedIn, setIsSignedIn] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
      initialiseSession();
  }, [isSignedIn]);

  const LoadingContent = () => (
      <Modal visible={true} header="Signing in AWS Event Finder...">
        <Box textAlign={"center"} margin={{bottom: 'm'}}>
          <Spinner size="large" />
        </Box>
      </Modal>
  )

  const ErrorContent = ({error}) => (
      <Box padding={"xxl"}>
        <ErrorAlert {...error}></ErrorAlert>
      </Box>
  )

  const initialiseSession = async () => {
    await setupAmplify()

    try {
      // Check if already logged in
      const user = await Auth.currentAuthenticatedUser();
      await User.init(user)

      if (!User.shared().isTester() && env === ENV_DEV) {
        await setError({title: 'Unauthorised', message: 'You are not a tester.'})
      }

      await setIsSignedIn(true)
    }
    catch (error) {
      await Auth.federatedSignIn();
    }
  }

  return error
      ? <ErrorContent error={error}></ErrorContent>
      : isSignedIn
        ? <HashRouter>
            <PageRouter/>
          </HashRouter>
        : <LoadingContent/>
}