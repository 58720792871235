import {Content} from "./content"
import {BaseAppLayout} from "../../app/baseAppLayout";
import useNotificationCenter from "../../utils/notificationCenter";
import {NotificationCenter} from "../custom_components/flashbar";

export const Subscriptions = () => {
    const notificationCenter = useNotificationCenter()

    return (
        <BaseAppLayout
            notifications={<NotificationCenter notifications={notificationCenter.notifications}/>}
            content={<Content notificationCenter={notificationCenter}/>}
            toolsHide={true}
            disableContentPaddings={false}
        />
    )
}