import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {
    Box,
    Button,
    ColumnLayout,
    Container,
    ContentLayout, Form, Input,
    Link, Modal, RadioGroup, Select,
    SpaceBetween,
    Spinner, Textarea
} from "@cloudscape-design/components";
import Header from "@cloudscape-design/components/header";
import {
    getConferenceEndpoint,
    handleResponse,
    retrieveConferenceSubmissionsEndpoint, submissionsEndpoint
} from "../../../utils/api";
import {getCfpDeadline, getCfpUrl, getDates, getLocation, getTopics} from "../../../utils/conference";
import {ErrorAlert} from "../../custom_components/alert";
import {FilteredTable} from "../../custom_components/filtered_table/table";
import {COLUMN_DEFINITIONS, FILTERING_PROPERTIES} from "./config";
import User from "../../../entities/user";
import FormField from "@cloudscape-design/components/form-field";

const GET_CONF_ERROR_TITLE = 'The conference could not be retrieved'
const GET_CONF_SUBMISSIONS_ERROR_TITLE = 'The proposed topics for the conference could not be retrieved'
const SUBMISSIONS_TEXTS = {loadingText: "Retrieving topics...", emptyTitle: 'No proposed topics', emptyMessage: 'No one has yet proposed a topic for this conference'}
const statusMapping = {IN_REVIEW: 'In review', ACCEPTED: 'Accepted', REJECTED: 'Rejected'}

export const Content = ({notificationCenter}) => {
    const id = useParams()['id']

    const [conference, setConference] = useState({})
    const [isLoadingConf, setIsLoadingConf] = useState(true)
    const [loadingConfError, setLoadingConfError] = useState(null)

    const [submissions, setSubmissions] = useState([])
    const [isLoadingSubmissions, setIsLoadingSubmissions] = useState(true)
    const [loadingSubmissionsError, setLoadingSubmissionsError] = useState(null)

    const [isInCreationMode, setIsInCreationMode] = useState(false)
    const [isConfirmingCreation, setIsConfirmingCreation] = useState(false)

    useEffect(() => {
        getConference(id)
        retrieveConferenceSubmissions(id)
    }, [id]);

    // # ---------------------- HELPERS --------------------- # //
    function getConference(id) {
        setConference({})
        setIsLoadingConf(true)
        setLoadingConfError(null)
        setIsInCreationMode(false)
        setIsConfirmingCreation(false)

        const options = {headers: {Authorization: User.shared().token}}

        fetch(getConferenceEndpoint(id), options)
            .then(handleResponse)
            .then(json => setConference(json.item))
            .catch(error => setLoadingConfError({title: GET_CONF_ERROR_TITLE, message: error.message}))
            .finally(() => setIsLoadingConf(false))
    }

    function retrieveConferenceSubmissions(id) {
        setSubmissions([])
        setIsLoadingSubmissions(true)
        setLoadingSubmissionsError(null)

        const options = {headers: {Authorization: User.shared().token}}

        fetch(retrieveConferenceSubmissionsEndpoint(id), options)
            .then(handleResponse)
            .then(json => setSubmissions(json.items))
            .catch(error => setLoadingSubmissionsError({title: GET_CONF_SUBMISSIONS_ERROR_TITLE, message: error.message}))
            .finally(() => setIsLoadingSubmissions(false))
    }

    function toggleCreationMode() {
        setIsInCreationMode(!isInCreationMode)
        setIsConfirmingCreation(false)
    }

    function confirmCreation(title, abstract, isPublic, isAbstractPublic, status) {
        const options = {
            method: 'POST',
            headers: {Authorization: User.shared().token},
            body: JSON.stringify({
                'title': title,
                'abstract': abstract,
                'isPublic': isPublic,
                'isAbstractPublic': isAbstractPublic,
                'status': status,
                'conf_id': conference.id,
                'conference': {
                    'name': conference.name,
                    'id': conference.id
                }
            })
        }

        // Dismiss the modal
        setIsInCreationMode(false)
        setIsConfirmingCreation(true)

        const addId = notificationCenter.addInfoNotification('Adding topic proposal...',
            'Please, wait while the topic proposal is being added.')

        fetch(submissionsEndpoint(), options)
            .then(handleResponse)
            .then((json) => {
                notificationCenter.addSuccessfulNotification('Topic proposal "' + title + '" successfully added!')
                setSubmissions(submissions => [json, ...submissions])
            })
            .catch(error => notificationCenter.addErrorNotification('The topic proposal could not be added', error.message))
            .finally(() => {
                setIsConfirmingCreation(false)
                notificationCenter.dismissNotification(addId)
            })
    }

    // # -------------------- COMPONENTS -------------------- # //
    const LoadingContent = () => (
        <Box textAlign={"center"}>
            <Spinner size="big" />
        </Box>
    )

    const ConferenceContent = () => (
        <SpaceBetween size='xxl'>
            <ConferenceDetails/>
            <ConferenceSubmissions/>
        </SpaceBetween>
    )

    const ConferenceDetails = () => (
        <Container
            header={
                <Header variant={'h2'}>{"Conference details"}</Header>
            }
        >
            {isLoadingConf === true
                ? <LoadingContent />
                : <ColumnLayout columns={3} variant="text-grid">
                    <SpaceBetween size="l">
                        <div>
                            <Box variant="awsui-key-label">Name</Box>
                            <div>{conference.name}</div>
                        </div>
                        <div>
                            <Box variant="awsui-key-label">Location</Box>
                            <div>{getLocation(conference)}</div>
                        </div>
                        <div>
                            <Box variant="awsui-key-label">Format</Box>
                            <div>{conference.format}</div>
                        </div>
                    </SpaceBetween>

                    <SpaceBetween size="l">
                        <div>
                            <Box variant="awsui-key-label">Dates</Box>
                            <div>{getDates(conference)}</div>
                        </div>
                        <div>
                            <Box variant="awsui-key-label">CFP deadline</Box>
                            <div>{getCfpDeadline(conference)}</div>
                        </div>
                        <div>
                            <Box variant="awsui-key-label">Topics</Box>
                            <div>{getTopics(conference)}</div>
                        </div>
                    </SpaceBetween>

                    <SpaceBetween size="l">
                        <div>
                            <Box variant="awsui-key-label">Web page</Box>
                            <div><Link external href={conference.url}>{conference.url}</Link></div>
                        </div>
                        <div>
                            <Box variant="awsui-key-label">CFP submission page</Box>
                            <div>{getCfpUrl(conference)}</div>
                        </div>
                    </SpaceBetween>
                </ColumnLayout>
            }
        </Container>
    )

    const ConferenceSubmissions = () => (
        loadingSubmissionsError ? (
            <ErrorAlert {...loadingSubmissionsError}/>
        ) : (
            <FilteredTable
                data={submissions}
                loading={isLoadingSubmissions}
                columnDefinitions={COLUMN_DEFINITIONS}
                filteringProperties={FILTERING_PROPERTIES}
                sortingColumn={COLUMN_DEFINITIONS[1]}
                texts={SUBMISSIONS_TEXTS}
                sortingDescending={true}
                header={
                    <Header
                        variant="h2"
                        counter={'(' + submissions.length + ')'}
                        description={'You can view the details of a topic proposal by clicking on its title, and all the proposals of a user by clicking on its name.'}
                    >
                        Proposed topics
                    </Header>
                }
            />
        )
    )

    function CreateModal() {
        const [title, setTitle] = useState('')
        const [abstract, setAbstract] = useState('')
        const [isPublic, setIsPublic] = useState(true)
        const [isAbstractPublic, setIsAbstractPublic] = useState(false)
        const [status, setStatus] = useState('IN_REVIEW')

        return (
            <Modal visible={isInCreationMode} onDismiss={toggleCreationMode}
                   header={'Add topic proposal'}
                   footer={
                       <Box float="right">
                           <SpaceBetween direction="horizontal" size="xs">
                               <Button variant="link" onClick={toggleCreationMode}>
                                   Cancel
                               </Button>
                               <Button variant="primary" onClick={() => confirmCreation(title, abstract, isPublic, isAbstractPublic, status)} disabled={title === ''}>
                                   Add
                               </Button>
                           </SpaceBetween>
                       </Box>
                   }
            >
                <form onSubmit={event => event.preventDefault()}>
                    <Form>
                        <SpaceBetween size="l">
                            <FormField label="Title" stretch={true}>
                                <Input
                                    placeholder='Submission title'
                                    value={title}
                                    onChange={event => setTitle(event.detail.value)}
                                    ariaRequired={true}
                                />
                            </FormField>

                            <FormField label="Abstract" stretch={true}>
                                <Textarea
                                    placeholder='Submission abstract'
                                    value={abstract}
                                    onChange={event => setAbstract(event.detail.value)}
                                />
                            </FormField>

                            <SpaceBetween size={'xl'} direction={'horizontal'}>
                                <FormField
                                    label="Visibility"
                                    stretch={false}
                                    description={(<><i>Public</i> submissions are visible by all users.</>)}
                                >
                                    <RadioGroup
                                        items={[{label: 'Public', value: true}, {label: 'Private', value: false}]}
                                        value={isPublic}
                                        onChange={event => setIsPublic(event.detail.value)}
                                    />
                                </FormField>

                                <FormField
                                    label="Abstract visibility"
                                    stretch={false}
                                    description={(<><i>Private</i> abstracts are not visible by other users, even if the
                                        submission is <i>Public</i>.</>)}
                                >
                                    <RadioGroup
                                        items={[{label: 'Public', value: true}, {label: 'Private', value: false}]}
                                        value={isAbstractPublic}
                                        onChange={event => setIsAbstractPublic(event.detail.value)}
                                    />
                                </FormField>

                                <FormField label='Status' stretch={true}>
                                    <Select
                                        selectedOption={{label: statusMapping[status], value: status}}
                                        onChange={event => setStatus(event.detail.selectedOption.value)}
                                        options={[
                                            {label: "In review", value: "IN_REVIEW"},
                                            {label: "Accepted", value: "ACCEPTED"},
                                            {label: "Rejected", value: "REJECTED"}
                                        ]}
                                    />
                                </FormField>
                            </SpaceBetween>
                        </SpaceBetween>
                    </Form>
                </form>
            </Modal>
        )
    }

    return (
        <ContentLayout
            header={
                <Header
                    variant={'h1'}
                    actions={!loadingConfError && Object.keys(conference).length > 0? <Button variant={'primary'} disabled={isConfirmingCreation} onClick={toggleCreationMode}>Add topic proposal</Button> : ''}
                >
                    {id}
                </Header>
            }
        >
            {loadingConfError === null
                ? <><ConferenceContent /><CreateModal/></>
                : <ErrorAlert {...loadingConfError} />
            }
        </ContentLayout>
    )
}