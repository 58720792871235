import {Content} from "./content"
import {BaseAppLayout} from "../../../app/baseAppLayout";

export const CfpsClosingSoon = () => {
    return (
        <BaseAppLayout
            content={<Content />}
            toolsHide={true}
            disableContentPaddings={false}
        />
    )
}