import {Route, Routes} from "react-router-dom"
import {Home} from "../components/home"
import {NotFound} from "../components/not_found"
import {RetrieveConferences} from "../components/_conferences/retrieve_conferences";
import {ManageConference} from "../components/_conferences/manage_conference";
import {ManageSubmission} from "../components/_submissions/manage_submission";
import {CfpsClosingSoon} from "../components/_conferences/cfps_closing_soon";
import {UserSubmissions} from "../components/_submissions/user_submissions";
import {ExploreSubmissions} from "../components/_submissions/explore_submissions";
import {Subscriptions} from "../components/_subscriptions";

export const PageRouter = () => {
    return (
        <Routes>
            <Route path='/' element={<Home/>} />
            <Route path='/conferences' element={<RetrieveConferences/>} />
            <Route path='/conferences/:id' element={<ManageConference/>} />
            <Route path='/conferences/:cid/:id' element={<ManageSubmission/>} />
            <Route path='/closing-cfps' element={<CfpsClosingSoon/>} />
            <Route path='/closing-cfps/:id' element={<ManageConference/>} />
            <Route path='/:username/submissions' element={<UserSubmissions/>} />
            <Route path='/:username/submissions/:id' element={<ManageSubmission/>} />
            <Route path='/submissions-discovery' element={<ExploreSubmissions/>} />
            <Route path='/submissions-discovery/:id' element={<ManageSubmission/>} />
            <Route path='/:username/subscriptions' element={<Subscriptions/>} />
            <Route path='*' element={<NotFound/>} />
        </Routes>
    )
}