import {env} from "./amplify";

const INVOKE_URL = 'https://rial3rof3h.execute-api.eu-west-1.amazonaws.com/' + env
const PT_URL = 'https://phonetool.amazon.com/users/'

export const phoneToolPage = (username) => {
    return PT_URL + username
}

export const closingCfpsEndpoint = () => {
    return INVOKE_URL + '/cfps'
}

export const conferencesEndpoint = () => {
    return INVOKE_URL + '/conferences'
}

export const getConferenceEndpoint = (id) => {
    return conferencesEndpoint() + '/' + id
}

export const submissionsEndpoint = () => {
    return INVOKE_URL + '/submissions'
}

export const userSubmissionsEndpoint = (username) => {
    return INVOKE_URL + '/' + username + '/submissions'
}

export const retrieveConferenceSubmissionsEndpoint = (id) => {
    return conferencesEndpoint() + '/' + id + '/submissions'
}

export const getSubmissionEndpoint = (id) => {
    return submissionsEndpoint() + '/' + id
}

export const subscriptionsEndpoint = () => {
    return INVOKE_URL + '/subscriptions'
}

export const userSubscriptionsEndpoint = (username) => {
    return INVOKE_URL + '/' + username + '/subscriptions'
}

export const getSubscriptionEndpoint = (id) => {
    return INVOKE_URL + '/subscriptions/' + id
}

export const handleResponse = (response) => {
    if (response.status === 204) return {}
    else if (response.ok) return response.json()
    else if (response.status === 400) throw Error('Bad request')
    else if (response.status === 404) throw Error('The requested resource was not found')
    else throw Error("You don't have permission to access this resource")
}