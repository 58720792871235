import {DateForm, formatDateTime} from "../../custom_components/dateFilterForms";
import {BaseLink} from "../../custom_components/link";
import {getCfpDeadline, getDates, getLocation, getTopics} from "../../../utils/conference";

const rawColumns = [
    {
        id: 'name',
        sortingField: 'name',
        header: 'Name',
        cell: item => (
            <div>
                <BaseLink href={"/closing-cfps/" + item.id}>{item.name}</BaseLink>
            </div>
        ),
        size: 'Medium',
    },
    {
        id: 'city',
        header: 'Location',
        cell: item => getLocation(item),
        size: 'Medium',
    },
    {
        id: 'dates',
        sortingField: 'startDate',
        header: 'Dates',
        cell: item => getDates(item),
        size: 'Small',
    },
    {
        id: 'cfpEndDate',
        sortingField: 'cfpEndDate',
        header: 'CFP deadline',
        cell: item => getCfpDeadline(item),
        size: 'Small',
    },
    {
        id: 'topics',
        header: 'Topics',
        cell: item => getTopics(item),
        size: 'Medium',
    },
];
export const FILTERING_PROPERTIES = [
    {
        propertyLabel: 'Name',
        key: 'name',
        groupValuesLabel: 'Names',
        operators: [':', '!:', '=', '!='],
    },
    {
        propertyLabel: 'City',
        key: 'city',
        groupValuesLabel: 'Cities',
        operators: ['=', '!='],
    },
    {
        propertyLabel: 'Country',
        key: 'country',
        groupValuesLabel: 'Countries',
        operators: ['=', '!='],
    },
    {
        propertyLabel: 'Continent',
        key: 'continent',
        groupValuesLabel: 'Continents',
        operators: ['=', '!='],
    },
    {
        propertyLabel: 'Topics',
        key: 'topics',
        groupValuesLabel: 'Topics',
        operators: [':', '!:'],
    },
    {
        key: 'cfpEndDate',
        propertyLabel: 'CFP close date',
        groupValuesLabel: 'CFP close date values',
        defaultOperator: '>',
        operators: ['<', '<=', '>', '>=', '='].map(operator => ({
            operator,
            form: DateForm,
            format: formatDateTime
        })),
    },
    {
        key: 'startDate',
        propertyLabel: 'Start date',
        groupValuesLabel: 'Start date values',
        defaultOperator: '>',
        operators: ['<', '<=', '>', '>=', '='].map(operator => ({
            operator,
            form: DateForm,
            format: formatDateTime
        })),
    }
];
export const COLUMN_DEFINITIONS = rawColumns.map(column => ({...column}));