import {Flashbar} from "@cloudscape-design/components";

const i18nStrings={
    ariaLabel: "Notifications",
    notificationBarAriaLabel: "View all notifications",
    notificationBarText: "Notifications",
    errorIconAriaLabel: "Error",
    warningIconAriaLabel: "Warning",
    successIconAriaLabel: "Success",
    infoIconAriaLabel: "Info",
    inProgressIconAriaLabel: "In progress"
}

export const NotificationCenter = ({notifications}) => (
    <Flashbar items={notifications} stackItems={true} i18nStrings={i18nStrings}/>
)