import * as React from "react";
import Header from "@cloudscape-design/components/header";
import {Box, Grid, ContentLayout} from "@cloudscape-design/components";
import Cards from "@cloudscape-design/components/cards";
import {config} from "./config";
import diagram from './diagram.png'
import {Image} from "@aws-amplify/ui-react";

export const Content = () => {
    return (
        <ContentLayout

            header={
                <Header>
                    <Box padding={{vertical: 'xxxl'}}>
                        <Grid
                            gridDefinition={[
                                {colspan: {l: 8, xxs: 10}, offset: {l: 2, xxs: 1}},
                                {colspan: {l: 7, xxs: 7}, offset: {l: 2, xxs: 1}},
                                {colspan: {l: 7, xxs: 7}, offset: {l: 2, xxs: 1}},
                            ]}
                        >
                            <Box variant="h1" fontWeight="heavy" padding="n" fontSize="display-l" color="inherit">
                                {config.title}
                            </Box>

                            <Box fontWeight="light" padding="n" fontSize="display-l" color="inherit">
                                {config.subtitle}
                            </Box>

                            <Box variant="p" padding='n' fontWeight="light">
                                {config.description}
                            </Box>
                        </Grid>
                    </Box>
                </Header>
            }
            >

            <Cards
                cardDefinition={{
                    header: e => e.name,
                    sections: [
                        {
                            id: "description",
                            content: e => e.description
                        }
                    ]
                }}
                cardsPerRow={[
                    {cards: 1},
                    {minWidth: 500, cards: 2},
                    {minWidth: 1000, cards: 4}
                ]}
                items={config.features}
            />


            <Box variant="h1" tagOverride="h2" padding={{top: 'l'}}>
                How it works
            </Box>

            <Box variant="p" padding={{top: 's'}}>
                {config.howItWorks}
            </Box>

            <Box padding={{top: 's'}}>
                <Image width={'100%'} alt={'Workflow diagram'} src={diagram}/>
            </Box>

        </ContentLayout>
    );
}