import React, {useEffect, useState} from "react";
import Header from "@cloudscape-design/components/header";
import {Box} from "@cloudscape-design/components";
import {COLUMN_DEFINITIONS, FILTERING_PROPERTIES} from "./config";
import {conferencesEndpoint, handleResponse} from "../../../utils/api";
import {FilteredTable} from "../../custom_components/filtered_table/table";
import {ErrorAlert} from "../../custom_components/alert";
import User from "../../../entities/user";

const GET_CONFERENCES_ERROR_TITLE = 'The conferences could not be retrieved'
const TEXTS = {loadingText: "Retrieving conferences...", emptyTitle: 'No conferences', emptyMessage: 'There are no upcoming conferences'}


export const Content = () => {
    const [conferences, setConferences] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(null)

    useEffect(() => {
        retrieveConferences()
    }, []);

    // # ---------------------- HELPERS --------------------- # //
    function retrieveConferences() {
        setIsLoading(true)
        setError(null)

        const options = {headers: {Authorization: User.shared().token}}

        fetch(conferencesEndpoint(), options)
            .then(handleResponse)
            .then(json => setConferences(json.items))
            .catch(error => setError({title: GET_CONFERENCES_ERROR_TITLE, message: error.message}))
            .finally(() => setIsLoading(false))
    }

    // # -------------------- COMPONENTS -------------------- # //
    const ErrorContent = () => (
        <Box padding={{vertical: 'l'}}>
            <ErrorAlert {...error}></ErrorAlert>
        </Box>
    )

    const ConferencesContent = () => (
        <FilteredTable
            data={conferences}
            loading={isLoading}
            texts={TEXTS}
            columnDefinitions={COLUMN_DEFINITIONS}
            filteringProperties={FILTERING_PROPERTIES}
            sortingColumn={COLUMN_DEFINITIONS[2]}
            variant={'full-page'}
            stickyHeader={true}
            stripedRows={true}
            header={
                <Header
                    variant="h1"
                    counter={'(' + conferences.length + ')'}
                    description={'In this page you can view all upcoming conferences. Past conferences are not shown. To view the details of a conference, click on its name.'}
                >
                    Upcoming conferences
                </Header>
            }
        />
    )

    return error ? (
        <ErrorContent />
    ) : (
        <ConferencesContent />
    );
}