const monthMapping = {
    '01': 'Jan.',
    '02': 'Feb.',
    '03': 'Mar.',
    '04': 'Apr.',
    '05': 'May',
    '06': 'June',
    '07': 'July',
    '08': 'Aug.',
    '09': 'Sept.',
    '10': 'Oct.',
    '11': 'Nov.',
    '12': 'Dec.',

}

export const formatDate = (date) => {
    if (!date) return '-'

    const components = date.split('-')
    return monthMapping[components[1]] + ' ' + components[2] + ', ' + components[0]
}

export const formatDateTime = (dateTime) => {
    if (!dateTime) return '-'

    const date = dateTime.split(' ')[0]
    const time = dateTime.split(' ')[1]

    const components = date.split('-')
    return monthMapping[components[1]] + ' ' + components[2] + ', ' + components[0] + ' at ' + time
}



export const smartFormatting = (startDate, endDate) => {
    if (startDate === endDate || !endDate) return formatDate(startDate)
    if (!startDate) return formatDate(endDate)

    const startComponents = startDate.split('-')
    const endComponents = endDate.split('-')

    // Same year, same month (Feb. 23-24, 2022)
    if (startComponents[0] === endComponents[0] && startComponents[1] === endComponents[1]) {
        return monthMapping[startComponents[1]] + ' ' + startComponents[2] + '-' + endComponents[2] + ', ' + startComponents[0]
    }

    // Same year (Feb. 23 - Mar. 24, 2022)
    return monthMapping[startComponents[1]] + ' ' + startComponents[2] + '-' + monthMapping[endComponents[1]] + ' ' + endComponents[2] + ', ' + startComponents[0]
}