import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {
    Alert,
    Box,
    Button,
    ColumnLayout,
    Container,
    ContentLayout,
    Form,
    Input,
    Modal, RadioGroup, Select,
    SpaceBetween, Spinner,
    Textarea
} from "@cloudscape-design/components";
import Header from "@cloudscape-design/components/header";
import FormField from "@cloudscape-design/components/form-field";
import User from "../../../entities/user";
import {getSubmissionEndpoint, handleResponse, submissionsEndpoint} from "../../../utils/api";
import {formatDateTime} from "../../../utils/date";
import {getAbstractVisibility, getConference, getStatus, getSubmitter, getVisibility} from "../../../utils/submission";
import {ErrorAlert} from "../../custom_components/alert";

const statusMapping = {IN_REVIEW: 'In review', ACCEPTED: 'Accepted', REJECTED: 'Rejected'}

export const Content = ({notificationCenter}) => {
    const id = useParams()['id']

    const navigate = useNavigate()

    const [submission, setSubmission] = useState(null)
    const [submissionError, setSubmissionError] = useState(null)

    const [isInEditionMode, setIsInEditionMode] = useState(false)
    const [isConfirmingEdition, setIsConfirmingEdition] = useState(false)

    const [isInDeletionMode, setIsInDeletionMode] = useState(false);
    const [isConfirmingDeletion, setIsConfirmingDeletion] = useState(false);

    const [title, setTitle] = useState('')
    const [abstract, setAbstract] = useState('')
    const [isPublic, setIsPublic] = useState(true)
    const [isAbstractPublic, setIsAbstractPublic] = useState(false)
    const [status, setStatus] = useState('')

    useEffect(() => {
        notificationCenter.clearNotifications()
        setSubmission(null)

        setIsInDeletionMode(false)
        setIsConfirmingDeletion(false)
        setIsInEditionMode(false)
        setIsConfirmingEdition(false)

        getSubmission(id)
    }, [id]);

    // # ---------------------- HELPERS --------------------- # //
    function getSubmission(id) {
        setSubmission(null)
        setSubmissionError(null)

        const options = {headers: {Authorization: User.shared().token}}

        fetch(getSubmissionEndpoint(id), options)
            .then(handleResponse)
            .then(json => {
                setSubmission(json.item)
            })
            .catch(error => {
                setSubmissionError({title: 'The submission could not be retrieved', message: error.message})
            })
    }

    function toggleEditionMode() {
        setTitle(submission.title)
        setAbstract(submission.abstract)
        setStatus(submission.status)
        setIsPublic(submission.isPublic)
        setIsAbstractPublic(submission.isAbstractPublic)

        setIsConfirmingEdition(false)
        setIsInEditionMode(!isInEditionMode)
    }

    function confirmEdition(updatedFields) {
        updatedFields.id = id

        const options = {
            method: 'PUT',
            headers: {'Content-Type': 'application/json', Authorization: User.shared().token},
            body: JSON.stringify(updatedFields)
        }

        const editId = notificationCenter.addInfoNotification('Updating submission...',
            'Please, wait while the submission is being updated.')

        setIsConfirmingEdition(true)

        fetch(submissionsEndpoint(), options)
            .then(handleResponse)
            .then(json => {
                setSubmission(json.item)
                notificationCenter.addSuccessfulNotification('Submission successfully updated!')
                toggleEditionMode()
            })
            .catch(error => notificationCenter.addErrorNotification('The submission could not be updated', error.message))
            .finally(() => {
                setIsConfirmingEdition(false)
                notificationCenter.dismissNotification(editId)
            })
    }

    function hasEditedSubmission(title, abstract, isPublic, isAbstractPublic, status) {
        return  submission.title !== title ||
                submission.abstract !== abstract ||
                submission.isPublic !== isPublic ||
                submission.isAbstractPublic !== isAbstractPublic ||
                submission.status !== status
    }

    function toggleDeletionMode() {
        setIsConfirmingDeletion(false)
        setIsInDeletionMode(!isInDeletionMode)
    }

    function confirmDeletion() {
        const options = {
            method: 'DELETE',
            headers: {Authorization: User.shared().token}
        }

        // Dismiss the modal
        setIsInDeletionMode(false)

        const deleteId = notificationCenter.addInfoNotification('Deleting submission...',
            'Please, wait while the submission is being deleted.')

        setIsConfirmingDeletion(true)

        fetch(getSubmissionEndpoint(id), options)
            .then(handleResponse)
            .then(() => navigate(-1))
            .catch(error => notificationCenter.addErrorNotification('The submission could not be deleted', error.message))
            .finally(() => {
                setIsConfirmingDeletion(false)
                notificationCenter.dismissNotification(deleteId)
            })
    }

    function isPerformingUpdates() {
        return isInDeletionMode || isConfirmingDeletion || isInEditionMode || isConfirmingEdition
    }

    // # -------------------- COMPONENTS -------------------- # //
    const LoadingContent = () => (
        <Box textAlign={"center"}>
            <Spinner size="big" />
        </Box>
    )

    const ErrorContent = ({texts}) => (
        <ErrorAlert {...texts}></ErrorAlert>
    )

    const ActionButtons = () => (
        <SpaceBetween direction="horizontal" size="xs">
            <Button disabled={isPerformingUpdates()} onClick={toggleEditionMode}>Edit</Button>
            <Button variant={"primary"} disabled={isPerformingUpdates()} onClick={toggleDeletionMode}>Delete</Button>
        </SpaceBetween>
    )

    const SubmissionView = () => (
        <Container
            header={
                <Header variant={'h2'}>{"Submission details"}</Header>
            }
        >
            {submission === null
                ? <LoadingContent/>
                : <ColumnLayout columns={3} variant="text-grid">
                    <SpaceBetween size="l">
                        <div>
                            <Box variant="awsui-key-label">Title</Box>
                            <div>{submission.title}</div>
                        </div>
                        <div>
                            <Box variant="awsui-key-label">Submitter</Box>
                            <div>{getSubmitter(submission)}</div>
                        </div>
                        <div>
                            <Box variant="awsui-key-label">Created on</Box>
                            <div>{formatDateTime(submission.createdOn)}</div>
                        </div>
                    </SpaceBetween>

                    <SpaceBetween size="l">
                        <div>
                            <Box variant="awsui-key-label">Submitted for</Box>
                            <div>{getConference(submission)}</div>
                        </div>

                        <SpaceBetween direction={'horizontal'} size={'l'}>
                            <div>
                                <Box variant="awsui-key-label">Visibility</Box>
                                <div>{getVisibility(submission)}</div>
                            </div>

                            <div>
                                <Box variant="awsui-key-label">Abstract visibility</Box>
                                <div>{getAbstractVisibility(submission)}</div>
                            </div>
                        </SpaceBetween>

                        <div>
                            <Box variant="awsui-key-label">Status</Box>
                            <div>{getStatus(submission)}</div>
                        </div>
                    </SpaceBetween>

                    <SpaceBetween size="l">
                        <div>
                            <Box variant="awsui-key-label">Abstract</Box>
                            <div>{submission.abstract}</div>
                        </div>
                    </SpaceBetween>
                </ColumnLayout>
            }
        </Container>
    )

    const EditionView = () => (
        <Form
            actions={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" disabled={isConfirmingEdition} onClick={toggleEditionMode}>Cancel</Button>
                    <Button
                        loading={isConfirmingEdition}
                        variant="primary"
                        onClick={() => confirmEdition({title: title, abstract: abstract, isPublic: isPublic, isAbstractPublic: isAbstractPublic, status: status})}
                        disabled={!hasEditedSubmission(title, abstract, isPublic, isAbstractPublic, status)}
                    >
                        Save changes
                    </Button>
                </SpaceBetween>
            }
        >
            <Container
                header={
                    <Header variant={'h2'}>{"Edit submission"}</Header>
                }
            >
                <SpaceBetween size="l">
                    <FormField label="Title" stretch={true}>
                        <Input
                            disabled={isConfirmingEdition}
                            placeholder='Submission title'
                            value={title}
                            onChange={event => setTitle(event.detail.value)}
                            ariaRequired={true}
                        />
                    </FormField>

                    <FormField label="Abstract" stretch={true}>
                        <Textarea
                            disabled={isConfirmingEdition}
                            placeholder='Submission abstract'
                            value={abstract}
                            onChange={event => setAbstract(event.detail.value)}
                        />
                    </FormField>

                    <SpaceBetween size={'xl'} direction={'horizontal'}>
                        <FormField
                            label="Visibility"
                            stretch={false}
                            description={(<><i>Public</i> submissions are visible by all users.</>)}
                        >
                            <RadioGroup
                                items={[{label: 'Public', value: true, disabled: isConfirmingEdition}, {label: 'Private', value: false, disabled: isConfirmingEdition}]}
                                value={isPublic}
                                onChange={event => setIsPublic(event.detail.value)}
                            />
                        </FormField>

                        <FormField
                            label="Abstract visibility"
                            stretch={false}
                            description={(<><i>Private</i> abstracts are not visible by other users, even if the submission is <i>Public</i>.</>)}
                        >
                            <RadioGroup
                                items={[{label: 'Public', value: true, disabled: isConfirmingEdition}, {label: 'Private', value: false, disabled: isConfirmingEdition}]}
                                value={isAbstractPublic}
                                onChange={event => setIsAbstractPublic(event.detail.value)}
                            />
                        </FormField>

                        <FormField label='Status' stretch={true}>
                            <Select
                                disabled={isConfirmingEdition}
                                selectedOption={{label: statusMapping[status], value: status}}
                                onChange={event => setStatus(event.detail.selectedOption.value)}
                                options={[
                                    {label: "In review", value: "IN_REVIEW"},
                                    {label: "Accepted", value: "ACCEPTED"},
                                    {label: "Rejected", value: "REJECTED"}
                                ]}
                            />
                        </FormField>

                    </SpaceBetween>
                </SpaceBetween>
            </Container>
        </Form>
    )

    function DeleteModal() {
        const [deleteInputText, setDeleteInputText] = useState('');
        const deleteConsentText = submission.id
        const inputMatchesConsentText = deleteInputText === deleteConsentText;

        useEffect(() => {
            setDeleteInputText('');
        }, []);

        const handleDeleteSubmit = event => {
            event.preventDefault();

            if (inputMatchesConsentText) confirmDeletion();
        };

        return (
            <Modal visible={isInDeletionMode}
               onDismiss={toggleDeletionMode}
               header={'Delete submission'}
               footer={
                   <Box float="right">
                       <SpaceBetween direction="horizontal" size="xs">
                           <Button variant="link" disabled={isConfirmingDeletion} onClick={toggleDeletionMode}>
                               Cancel
                           </Button>
                           <Button variant="primary" loading={isConfirmingDeletion} onClick={confirmDeletion} disabled={!inputMatchesConsentText}>
                               Delete
                           </Button>
                       </SpaceBetween>
                   </Box>
               }
            >
                <SpaceBetween size="m">
                    <Box variant="span">
                        Permanently delete submission? You can’t undo this action.
                    </Box>

                    <Alert type="warning" statusIconAriaLabel="Warning">
                        Proceeding with this action will delete the submission and all its related information, including the abstract.
                    </Alert>

                    <Box>To avoid accidental deletions, we ask you to provide additional written consent.</Box>

                    <form onSubmit={handleDeleteSubmit}>
                        <FormField label={`To confirm this deletion, type "${deleteConsentText}".`}>
                            <Input
                                placeholder={deleteConsentText}
                                onChange={event => setDeleteInputText(event.detail.value)}
                                value={deleteInputText}
                                ariaRequired={true}
                            />
                        </FormField>
                    </form>
                </SpaceBetween>
            </Modal>
        )
    }

    return (
        <ContentLayout
            header={
                <Header
                    variant={'h1'}
                    actions={User.shared().owns(submission) &&
                        <ActionButtons/>
                    }
                >
                    {id}
                </Header>
            }
        >

            {submissionError !== null
                ? <ErrorContent texts={submissionError}/>
                : (isInEditionMode ? <EditionView/> : <SubmissionView/>)
            }

            {submission !== null &&
                <DeleteModal/>
            }

        </ContentLayout>
    )
}