import {formatDateTime} from "../../../utils/date";
import {getConference, getStatus} from "../../../utils/submission";
import {BaseLink} from "../../custom_components/link";

const rawColumns = [
    {
        id: 'title',
        sortingField: 'title',
        header: 'Title',
        cell: item =>  (
            <div>
                <BaseLink href={'/' + item.owner + "/submissions/" + item.id}>{item.title}</BaseLink>
            </div>
        ),
        size: 'Medium',
    },
    {
        id: 'conference',
        sortingField: 'conference',
        header: 'Submitted for',
        cell: item => getConference(item),
        size: 'Small',
    },
    {
        id: 'createdOn',
        sortingField: 'createdOn',
        header: 'Created on',
        cell: item => formatDateTime(item.createdOn),
        size: 'Medium',
    },
    {
        id: 'status',
        sortingField: 'status',
        header: 'Status',
        cell: item => getStatus(item),
        size: 'Small',
    }
];

export const FILTERING_PROPERTIES = [
    {
        propertyLabel: 'Title',
        key: 'title',
        groupValuesLabel: 'Titles',
        operators: [':', '!:', '=', '!='],
    }
];

export const COLUMN_DEFINITIONS = rawColumns.map(column => ({...column}));