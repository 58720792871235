import {Badge, Link, SpaceBetween} from "@cloudscape-design/components";
import {formatDate, smartFormatting} from "./date";

export const getLocation = (conference) => {
    return conference.format === 'Virtual'? 'Virtual' : conference.city + ', ' + conference.country
}

export const getTopics = (conference) => {
    let badges = []

    conference.topics.slice(0, 3).forEach(function (topic) {
        badges.push(<Badge>{topic}</Badge>)
    })

    return (
        <SpaceBetween direction="horizontal" size="xs">
            {badges}
        </SpaceBetween>
    )
}

export const getDates = (conference) => {
    return smartFormatting(conference.startDate, conference.endDate)
}

export const getCfpDeadline = (conference) => {
    if (!conference.cfpEndDate) return formatDate(conference.cfpEndDate)

    const date = Date.parse(conference.cfpEndDate)

    if (date < new Date()) {
        return (
            <p style={{color: 'red'}}>{formatDate(conference.cfpEndDate)}</p>
        )
    }
    else return formatDate(conference.cfpEndDate)
}

export const getCfpUrl = (conference) => {
    if (!conference.cfpUrl) return '-';

    return <Link external href={conference.url}>{conference.cfpUrl}</Link>
}