import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {
    Box, Button
} from "@cloudscape-design/components";
import Header from "@cloudscape-design/components/header";
import User from "../../../entities/user";
import {handleResponse, phoneToolPage, userSubmissionsEndpoint} from "../../../utils/api";
import {ErrorAlert} from "../../custom_components/alert";
import {FilteredTable} from "../../custom_components/filtered_table/table";
import {COLUMN_DEFINITIONS, FILTERING_PROPERTIES} from "./config";

const TEXTS_OTHER = {loadingText: "Retrieving proposed topics...", emptyTitle: 'No proposed topics', emptyMessage: 'This user user hasn\'t shared any topic proposals.'}
const TEXTS_SELF = {loadingText: "Retrieving proposed topics...", emptyTitle: 'No proposed topics', emptyMessage: 'To add a topic proposal, navigate to the details page of a conference and click on the "Add topic proposal" button.'}

export const Content = () => {
    const username = useParams()['username']
    const isSelf = username === User.shared().getAttributes().username

    const [submissions, setSubmissions] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        getUserSubmissions(username)
    }, [username]);

    // # ---------------------- HELPERS --------------------- # //
    function getUserSubmissions(username) {
        setIsLoading(true)
        setError(null)

        const options = {headers: {Authorization: User.shared().token}}

        fetch(userSubmissionsEndpoint(username), options)
            .then(handleResponse)
            .then(json => setSubmissions(json.items))
            .catch(error => setError({title: 'The topic proposals could not be retrieved', message: error.message}))
            .finally(() => setIsLoading(false))
    }

    // # -------------------- COMPONENTS -------------------- # //
    const ErrorContent = () => (
        <Box padding={{vertical: 'l'}}>
            <ErrorAlert {...error}></ErrorAlert>
        </Box>
    )

    const SubmissionsContent = () => (
        <FilteredTable
            data={submissions}
            loading={isLoading}
            texts={isSelf ? TEXTS_SELF : TEXTS_OTHER}
            columnDefinitions={COLUMN_DEFINITIONS}
            filteringProperties={FILTERING_PROPERTIES}
            sortingColumn={COLUMN_DEFINITIONS[2]}
            variant={'full-page'}
            stickyHeader={true}
            stripedRows={true}
            sortingDescending={true}
            header={
                <Header
                    variant="h1"
                    counter={'(' + submissions.length + ')'}
                    description={'You can view the details of a topic proposal by clicking on its title, and the details of the conference it was submitted for by clicking on its title.'}
                    actions={!isSelf ? <Button iconName="external" target={'_blank'} href={phoneToolPage(username)}>PhoneTool</Button> : ''}
                >
                    {isSelf ? 'My proposed topics' : username + "'s proposed topics"}

                </Header>
            }
        />
    )

    return error ? (
        <ErrorContent />
    ) : (
        <SubmissionsContent></SubmissionsContent>
    );
}