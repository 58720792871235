import {useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {AppLayout, BreadcrumbGroup} from "@cloudscape-design/components";
import {BaseSideNavigation} from "./baseSideNavigation";
import {BaseHelpPanel} from "./baseHelpPanel";
import User from "../entities/user";

export const BaseAppLayout = (props) => {
    const [toolsOpen, setToolsOpen] = useState(false)
    const appLayout = useRef();

    let navigate = useNavigate()
    function buildBreadcrumbs() {
        if (window.location.hash === '') return

        const hashes = window.location.hash.split('/')

        // Remove all empty hashes
        while (hashes.indexOf('') > -1) {
            hashes.splice(hashes.indexOf(''), 1)
        }

        let items = []

        hashes.forEach(function (hash) {
            // Avoid adding a breadcrumb if on home page
            if (hash === '#' && hashes.length > 1) {
                items.push({text: 'AWS Event Finder', href: '/'})
            }
            else if (hash === 'conferences') {
                items.push({text: 'Upcoming conferences', href: '/conferences'})
            }
            else if (hash === 'closing-cfps') {
                items.push({text: 'CFPs closing soon', href: '/closing-cfps'})
            }
            else if (hash === 'submissions-discovery') {
                items.push({text: 'Explore proposed topics', href: '/submissions-discovery'})
            }
            else if (hash === 'submissions') {
                // Check whose submissions have we retrieved
                const parentText = items[items.length - 1].text

                if (parentText === User.shared().getAttributes().username) {
                    items[items.length - 1].text = 'My proposed topics'
                }
                else {
                    items[items.length - 1].text = 'Proposed topics'
                }

                items[items.length - 1].href = '/' + parentText + '/submissions'
            }
            else if (hash === 'subscriptions') {
                // Check whose submissions have we retrieved
                const parentText = items[items.length - 1].text

                items[items.length - 1].text = 'My subscriptions'
                items[items.length - 1].href = '/' + parentText + '/subscriptions'
            }
            // Add a resource breadcrumb (for instance a conference)
            else if (hash !== '#') {
                const parentHref = items[items.length - 1].href
                items.push({text: hash, href: parentHref + '/' + hash})
            }
        })

        return items.length > 1 ? items : []
    }

    return (
        <AppLayout
            ref={appLayout}
            navigation={<BaseSideNavigation />}
            tools={<BaseHelpPanel />}
            toolsOpen={toolsOpen}
            onToolsChange={({ detail }) => setToolsOpen(detail.open)}
            breadcrumbs={
                <BreadcrumbGroup
                    items={buildBreadcrumbs()}
                    onClick={event => {
                        if (!event.detail.external) {
                            event.preventDefault();
                            navigate(event.detail.href)
                        }
                    }}
                />
            }
            {...props}
        >
        </AppLayout>
    );
}